import { ADD_NOTIFICATION } from '../../components/Notifications/Notifications.context';
import cleanText from '../../utils/cleanText';
import { setIsNextStep, UPDATE_TIME_STAMP, setCurrentStep } from './app';
import Config from '../../libs/Config';
import leftFillNum from '../../utils/leftFillNum';
import { step } from '../Reducers/app';

export const FETCH_TRANSLATION_DATA_BEGIN = 'FETCH_TRANSLATION_DATA_BEGIN';
export const FETCH_TRANSLATION_DATA_SUCCESS = 'FETCH_TRANSLATION_DATA_SUCCESS';
export const FETCH_TRANSLATION_DATA_FAILURE = 'FETCH_TRANSLATION_DATA_FAILURE';
export const FETCH_ORIGINAL_DATA_BEGIN = 'FETCH_ORIGINAL_DATA_BEGIN';
export const FETCH_ORIGINAL_DATA_SUCCESS = 'FETCH_ORIGINAL_DATA_SUCCESS';
export const FETCH_ORIGINAL_DATA_FAILURE = 'FETCH_ORIGINAL_DATA_FAILURE';
export const SAVE_TRANSLATION_DATA_BEGIN = 'SAVE_TRANSLATION_DATA_BEGIN';
export const SAVE_TRANSLATION_DATA_SUCCESS = 'SAVE_TRANSLATION_DATA_SUCCESS';
export const SAVE_TRANSLATION_DATA_URL_SUCCESS = 'SAVE_TRANSLATION_DATA_URL_SUCCESS';
export const SAVE_TRANSLATION_DATA_FAILURE = 'SAVE_TRANSLATION_DATA_FAILURE';
export const SAVE_TRANSLATION_DATA_URL_FAILURE = 'SAVE_TRANSLATION_DATA_URL_FAILURE';
export const RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED =
  'RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED';
export const EDIT_TRANSLATION = 'EDIT_TRANSLATION';
export const EDIT_TRANSLATION_LIST = 'EDIT_TRANSLATION_LIST';
export const IS_TRANSLATION_FETCHED = 'IS_TRANSLATION_FETCHED';
export const HANDLE_KEY_STROKES = 'HANDLE_KEY_STROKES';
export const HANDLE_KEY_STROKES_FROM_DB = 'HANDLE_KEY_STROKES_FROM_DB';
export const HANDLE_BUTTON_CLICKS = 'HANDLE_BUTTON_CLICKS';
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';
export const UPDATE_SENTENCE_BLOCKS = 'UPDATE_SENTENCE_BLOCKS';

export const fetchTranslationData =
  (notificationsDispatcher, params) => async (dispatch) => {
    try {
      const { id: ident, token } = params;

      dispatch({ type: FETCH_TRANSLATION_DATA_BEGIN });

      const response = await fetch(
        `${Config.apiUrl}/translation/get-data-for-textarea-step`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({ ident }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error();
      }

      dispatch({
        type: UPDATE_TIME_STAMP,
        payload: {
          data: { videotimestamp: response.data.videotimestamp },
        },
      });

      dispatch({
        type: FETCH_TRANSLATION_DATA_SUCCESS,
        payload: response.data,
      });

      dispatch({
        type: IS_TRANSLATION_FETCHED,
        payload: { isFetched: true },
      });
    } catch (error) {
      console.error(error);

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Failed to load project.',
            content: `Check that the project identifier in the address bar is valid then try again.
                      If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: FETCH_TRANSLATION_DATA_FAILURE, payload: error });
    }
  };

export const saveTranslationDataFromSessionStorage =
  (notificationsDispatcher, params) => async (dispatch, getState) => {
    try {
      return;
      const { id: ident, token, isCorrection } = params;
      const {
        data: { children, finished, content: plainText ,keyStrokes , keyStrokesFromDB, buttonclicks, buttonclicksFromDB}
      } = JSON.parse(sessionStorage.getItem("translationData"));
      let content = children.map((current) => cleanText(current.text)).join('\n\n');
      if (isCorrection) content = plainText;
      const { data: { videotimestamp } } = getState().app;

      dispatch({ type: SAVE_TRANSLATION_DATA_BEGIN });

      const keyStrokesToDB = Number(keyStrokesFromDB) + Number(keyStrokes);
      const buttonclicksToDB = Number(buttonclicksFromDB) + Number(buttonclicks);

      let offline = 1;
      const response = await fetch(
        `${Config.apiUrl}/translation/save-textarea-data`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({ ident, content, videotimestamp, finished, keyStrokesToDB, buttonclicksToDB, offline }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error(response.msg);
      }

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'The project stored locally. has been saved successfully.',
            content: `Please check your internet connection and try again later.`,
          },
          timeout: 3000,
          intent: 'success',
          icon: 'tick',
        },
      });

      dispatch({
        type: SAVE_TRANSLATION_DATA_SUCCESS,
        payload: response.data,
      });

      if (sessionStorage.getItem("translationData")) {
        sessionStorage.removeItem("translationData");
      }
    } catch (error) {
      console.error(error);

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Project backup failed.',
            content: `Failed to save the project stored locally. Please try again later. If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: SAVE_TRANSLATION_DATA_FAILURE, payload: error });
    }
  };

export const saveTranslationData =
  (notificationsDispatcher, navigate, params) => async (dispatch, getState) => {
    try {
      /*if (sessionStorage.getItem("translationData")) {
        await saveTranslationDataFromSessionStorage(notificationsDispatcher, params)(dispatch, getState);
      }*/
      const { id: ident, token, isCorrection } = params;
      const {
        data: {
          children,
          finished,
          content: plainText,
          keyStrokes,
          keyStrokesFromDB,
          buttonclicks,
          buttonclicksFromDB,
        },
      } = getState().translation;

      let content = children.map((current) => cleanText(current.text)).join('\n\n');
      if (isCorrection) content = plainText;


      const {
        data: { videotimestamp, step: nextStep },
        isNextStep,
      } = getState().app;

      if (process.env.NODE_ENV === 'development') return;

      dispatch({ type: SAVE_TRANSLATION_DATA_BEGIN });

      const keyStrokesToDB = Number(keyStrokesFromDB) + Number(keyStrokes);
      const buttonclicksToDB = Number(buttonclicksFromDB) + Number(buttonclicks);

      const response = await fetch(
        `${Config.apiUrl}/translation/save-textarea-data`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({
            ident,
            content,
            videotimestamp,
            finished,
            keyStrokesToDB,
            buttonclicksToDB,
          }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error(response.msg);
      }

      const { sentenceblocks } = response.data;

      if (sentenceblocks) {
        dispatch(updateSentenceBlocks(sentenceblocks));
      }

      const {
        data: {
          children: updatedChildren,
        },
      } = getState().translation;

      let missingTranslationIndex = -1;

      for (let i = 0; i < updatedChildren.length; i++) {
        if (updatedChildren[i].text.length === 0) {
          missingTranslationIndex = i;
          break;
        }
      }

      const missingTranslation = updatedChildren.filter(
        (current) => current.text.length === 0
      );

      if (isNextStep && missingTranslation.length > 0 && missingTranslationIndex !== -1) {
        const indexOfFirtsMissingTranslation = leftFillNum(
          missingTranslationIndex + 1,
          updatedChildren.length.toString().length
        );
        dispatch(
          setCurrentStep({
            name: step.TRANSLATION,
            path: `/translation/${ident}?token=${token}`,
          })
        );
        notificationsDispatcher({
          type: ADD_NOTIFICATION,
          payload: {
            message: {
              title: `Missing translation(s).`,
              content: `Please complete each line of translation(s) starting in line: ${indexOfFirtsMissingTranslation}.`,
            },
            timeout: 10000,
            intent: 'warning',
            icon: 'warning-sign',
          },
        });

        dispatch({ type: RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED });
        return;
      } else if (isNextStep && missingTranslation.length <= 0 && !finished) {
        dispatch(setIsNextStep(false));
        navigate(nextStep.path);
        return;
      }

      const { editeururl } = response.data;

      if (finished && editeururl) {
        dispatch({
          type: SAVE_TRANSLATION_DATA_URL_SUCCESS,
          payload: response.data,
        });
      }

      if (finished && !editeururl) {
        dispatch({
          type: SAVE_TRANSLATION_DATA_URL_FAILURE,
          payload: {
            data: { finished: false },
            error: 'editeururl empty or undefined',
          },
        });

        notificationsDispatcher({
          type: ADD_NOTIFICATION,
          payload: {
            message: {
              title: `Redirecting to the next step (Editor) failed.`,
              content: `Please try again later. If the problem persists, please contact technical support.`,
            },
            timeout: 100000,
            intent: 'danger',
            icon: 'error',
          },
        });
      }

      dispatch({ type: RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED });

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'The project has been saved successfully.',
          },
          timeout: 3000,
          intent: 'success',
          icon: 'tick',
        },
      });

      dispatch({
        type: SAVE_TRANSLATION_DATA_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);

      if (sessionStorage.getItem("translationData")) {
        sessionStorage.removeItem("translationData");
      }
      sessionStorage.setItem('translationData', JSON.stringify(getState().translation));

      //dispatch({ type: RESET_EDIT_TRANSLATION_SINCE_LAST_SAVED });

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Project backup failed.',
            content: `The project has been stored locally. Please try again later. If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: SAVE_TRANSLATION_DATA_FAILURE, payload: error });
    }
  };

export const fetchOriginalData =
  (notificationsDispatcher, params) => async (dispatch) => {
    try {
      const { id: ident, token } = params;

      dispatch({ type: FETCH_ORIGINAL_DATA_BEGIN });

      const response = await fetch(
        `${Config.apiUrl}/translation/get-original-data`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'post',
          body: JSON.stringify({ ident }),
        }
      ).then((res) => res.json());

      if (response.status !== 'OK') {
        throw new Error();
      }

      dispatch({ type: FETCH_ORIGINAL_DATA_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);

      notificationsDispatcher({
        type: ADD_NOTIFICATION,
        payload: {
          message: {
            title: 'Failed to load project.',
            content: `Check that the project identifier in the address bar is valid then try again. If the problem persists, please contact technical support.`,
          },
          timeout: 100000,
          intent: 'danger',
          icon: 'error',
        },
      });

      dispatch({ type: FETCH_ORIGINAL_DATA_FAILURE, payload: error });
    }
  };

export const editTranslationList = (translation) => (dispatch) => {
  dispatch({
    type: EDIT_TRANSLATION_LIST,
    payload: {
      data: { ...translation },
    },
  });
};

export const editTranslation = (translation) => (dispatch) => {
  dispatch({
    type: EDIT_TRANSLATION,
    payload: {
      data: { ...translation },
    },
  });
};

export const setKeyStrokes = () => (dispatch) => {
  dispatch({
    type: HANDLE_KEY_STROKES,
  });
};

export const setTranslationFetched = (isFetched) => (dispatch) => {
  dispatch({
    type: IS_TRANSLATION_FETCHED,
    payload: {
      isFetched,
    },
  });
};

export const setSearchValue = (search) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_VALUE,
    payload: {
      search,
    },
  });
};

export const updateSentenceBlocks = (sentenceblocks) => (dispatch) => {
  dispatch({
    type: UPDATE_SENTENCE_BLOCKS,
    payload: sentenceblocks,
  });
}
